<template>
  <div>
    <div class="header text-center">
      <h3 class="title">ADMIN</h3>
    </div>

    <div class="md-layout">
      
      <AdminbLock :name="'restaurant-api'"></AdminbLock>

    </div>
  </div>
</template>

<script>
import AdminbLock from "@/pages/Dashboard/AdminbLock.vue";

import { getSettingsAction, getAdminAction, getDbInfoAction } from "@/pages/Auth/actions";

export default {
  components:{
    AdminbLock
  },
  data() {
    return {
      dbs: { result: [] }, // Asegúrate de que dbs sea un objeto reactivo con un array result
      info: {}
    };
  },
  created() {
    this.subdomain = window.location.hostname;
    const parts = this.subdomain.split(".");
    if (parts[0] === 'localhost') {
    
      }
  },
  methods: {
    async getDbs() {
      const data = await getAdminAction();
      this.$set(this.dbs, 'result', data.result); // Usar $set para reactividad
    },
    async open(name) {
      if(!this.info[name]){

      const dbInfo = await getDbInfoAction(name);
      this.$set(this.info, name, dbInfo); // Usar $set para que sea reactivo
      }
    }
  }
};
</script>
