<template>
  <div class="card p-4">
    <div class="md-layout">
      <div class="md-layout-item md-size-100 mx-auto text-center">
        
        <h3 class="title md-primary">
          {{$t('cancelableSubscription')}}
        </h3>
          <h6 v-if="lastPayment && credits == 101" class="text-danger mt-4"> 
          Último pago registrado {{formatDate(lastPayment)}}
          </h6>

      </div>
    </div>
   <md-field slot="description"  class="md-form-group countryregister" v-if="!this.hascountry">
            <md-icon>public</md-icon>
            <country-select v-model="country" :country="country" 
              @input="changeCountry()" />
          </md-field>

    <!-- Planes de Precios -->
    <div class="md-layout mt-4">
     
      <div class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 p-2">
        <pricing-card card-class="md-card-plain card p-2" icon-color="icon-primary">
          <h6 slot="category" class="category text-primary">{{$t('unlimitedPlan')}}</h6>
            
          <div slot="category" class="w-100 float-left"><MercadoPago v-if="isMercadoPagoCountry() && credits != 101"></MercadoPago></div>
   

          <h3 slot="title" class="title text-secondary" style="text-decoration: line-through;"  v-if="isMercadoPagoCountry()">40.000 ARS</h3>
          <h4 slot="title" class=" text-danger"   v-if="isMercadoPagoCountry()">25.000 ARS x mes</h4>
          <span class="text-danger" slot="title" >Promo febrero 2025, plan ilimitado 25.000 pesos por mes. Sin cambios de precio durante todo el año 2025. luego mantendrás siempre plan ilimitado pagando el precio del plan "bases".</span>

            <div   slot="title" class="countdown text-danger">
    <strong>Quedan {{ days }} días {{ hours }} horas {{ minutes }} minutos {{ seconds }}.</strong>
  </div>

          <md-icon slot="icon" class="md-primary">business</md-icon>
         
          
          <div slot="description" class="my-4 w-100 align-left " >
              <span v-for="module in modules">
                  <strong :class="module.check3 ? 'text-primary' : 'text-secondary'">
                    <router-link class="cursor-pointer"  :to="'/' + module.link">
                      <md-icon  v-if="module.check3" class="text-primary">check</md-icon>
                      <md-icon  v-else>close</md-icon> {{module.title}}</router-link>:
                  </strong>
                  {{module.desc}}<br>
              </span>
          </div>


            <h5  slot="description" v-if="credits == 101" >{{$t('alreadySubscribedUnlimited')}}</h5>

          <h5 slot="description" class="title text-secondary" style="text-decoration: line-through;"   v-if="isMercadoPagoCountry() && credits != 101">40.000 ARS x mes <md-icon slot="icon" class="md-primary">chat</md-icon> </h5>

          <h5 slot="description" class="title text-primary"  v-if="isMercadoPagoCountry() && credits != 101">Oferta Febrero 2025 Plan ilimitado 25.000 ARS x mes <md-icon slot="icon" class="md-primary">chat</md-icon> </h5>
          <div slot="footer" class="w-100 float-left"><MercadoPago v-if="isMercadoPagoCountry() && credits != 101"></MercadoPago></div>
      
          

          </pricing-card>
      </div>
      <div class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 p-2">
        <pricing-card card-class="md-card-plain card text-leftcard p-2" icon-color="icon-primary">
          <h6 slot="category" class="category text-primary">PLAN BASES</h6>
          <md-icon slot="icon" class="md-primary">person</md-icon>
          <h3 v-if="false" slot="title" class="title text-primary">{{ price.amount }} {{ price.currency }}</h3>
          <h3 slot="title" class="title text-secondary" style="text-decoration: line-through;"  v-if="isMercadoPagoCountry()">25.000 ARS</h3>
          <h3 slot="title" class="title text-primary" v-else>25,90 €</h3>
          <small class="text-primary" slot="title" >{{$t('perMonth')}}</small>
          
          <div slot="description" class="my-4 w-100 align-left " >

              <span v-for="module in modules">
                  <strong :class="module.check1 ? 'text-primary' : 'text-secondary'">
                    <router-link class="cursor-pointer"  :to="'/' + module.link">
                      <md-icon  v-if="module.check1" class="text-primary">check</md-icon>
                      <md-icon  v-else>close</md-icon> {{module.title}}</router-link>:
                  </strong>
                  {{module.desc}}<br>
              </span>
           
          </div>
            <h5  slot="footer" v-if="credits == 101" >{{$t('alreadySubscribedUnlimited')}}</h5>

          <h5 slot="description" class="title text-secondary" style="text-decoration: line-through;"  v-if="isMercadoPagoCountry() && credits != 101">25.000 ARS x mes <md-icon slot="icon" class="md-primary">chat</md-icon> </h5>
          <MercadoPago slot="footer" v-if="isMercadoPagoCountry() && credits != 101 && false"></MercadoPago>
          
          <strong slot="footer" v-if="isMercadoPagoCountry() && credits != 101">Oferta Febrero 2025 Plan ilimitado 25.000 ARS</strong>

          <StripeButton slot="footer" v-if="!isMercadoPagoCountry()" :subscription="credits == 101 ? true : false"  plan='basic'></StripeButton>
     

        </pricing-card>
      </div>

      <div class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 p-2">
        <pricing-card card-class="md-card-plain card p-2" icon-color="icon-primary">
          <h6  slot="category" class="category text-primary">PLAN PRO</h6>
          <md-icon slot="icon" class="md-primary">storefront</md-icon>
          <h3 v-if="false" slot="title" class="title text-primary">{{ price.amount }} {{ price.currency }}</h3>
          <h3 slot="title" class="title text-secondary" style="text-decoration: line-through;"  v-if="isMercadoPagoCountry()">35.000 ARS</h3>
          <h3 slot="title" class="title text-primary" v-else>45,90 €</h3>
          <small class="text-primary" slot="title" >{{$t('perMonth')}}</small>
         
          <div slot="description" class="my-4 w-100 align-left " >
              <span v-for="module in modules">
                  <strong :class="module.check2 ? 'text-primary' : 'text-secondary'">
                    <router-link class="cursor-pointer"  :to="'/' + module.link">
                      <md-icon  v-if="module.check2" class="text-primary">check</md-icon>
                      <md-icon  v-else>close</md-icon> {{module.title}}</router-link>:
                  </strong>
                  {{module.desc}}<br>
              </span>
          </div>
            <h5  slot="footer" v-if="credits == 101" >{{$t('alreadySubscribedUnlimited')}}</h5>

          <md-field slot="description"  class="md-form-group countryregister" v-if="!this.hascountry">
            <md-icon>public</md-icon>
          
               <country-select v-model="country" :country="country" 
              @input="changeCountry()" />
          </md-field>

          <MercadoPago slot="footer" v-if="isMercadoPagoCountry() && credits != 101 && false"></MercadoPago>
          

          <h5 slot="description" class="title text-secondary" style="text-decoration: line-through;"   v-if="isMercadoPagoCountry() && credits != 101">35.000 ARS x mes <md-icon slot="icon" class="md-primary">chat</md-icon> </h5>

          <strong slot="footer" v-if="isMercadoPagoCountry() && credits != 101">Oferta Febrero 2025 Plan ilimitado <br> 25.000 ARS</strong>

          <StripeButton slot="footer" v-if="!isMercadoPagoCountry()" :subscription="credits == 101 ? true : false" plan="pro"></StripeButton>
          </pricing-card>
      </div>



    </div>
  </div>
</template>

<script>
import { PricingCard } from "@/components";
import { getPriceAction } from "@/pages/MercadoPago/actions";
import { editUser } from "@/pages/Auth/actions";
import MercadoPago from "@/pages/Dashboard/Components/MercadoPago.vue";
import StripeButton from "@/pages/Stripe/StripeButton.vue";
import Swal from "sweetalert2";
import { confirmSubscriptionPayment } from "@/pages/Stripe/actions"; // Importa la función de suscripción de Stripe
import { getCredits } from "@/pages/Orders/actions";
import permissionsMixin from '@/mixins/permissionsMixin';


export default {
  mixins: [permissionsMixin],
  data() {
    return {
      status:false,
      hascountry:false,
      lastPayment: false,
      targetDate: new Date("2025-03-01T00:00:00").getTime(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      credits:false,
      price: false,
      priceText: '10.000 pesos argentinos',
      selectedCountry: null,
      country:false,
      sessionId:false,
      modules:[
            {title: "Módulo QR",
            link: "qrs",
            desc: "Control de accesos QR, Historial de accesos",
            check1: true,
            check2: true,
            check3: true}, 

            {title: "Módulo Clases",
            link: "classes",
            desc: "Creación de clases, Calendarios, control de inscripciones a las clases",
            check1: true,
            check2: true,
            check3: true},

            {title: "Módulo Rutinas",
            link: "planning",
            desc: "Asignación de rutinas, creación de rutinas, creación de cuentas para profesores",
            check1: true,
            check2: true,
            check3: true},

            {title: "Módulo Mercado Pago:",
            link: "payments",
            desc: "Cobros automáticos por Mercado Pago",
            check1: false,
            check2: true,
            check3: true},

            {title: "Módulo Recibos",
            link: "payments",
            desc: "El cliente sube una captura de su transferencia realizada y el sistema lo registra como pagado",
            check1: false,
            check2: true,
            check3: true},

            {title: "Módulo Emails",
            link: "payments",
            desc: "Alertas por emails a los clientes cuando se acerca la fecha de su pago o cuando esta retrasado",
            check1: false,
            check2: false,
            check3: true},

            {title: "Módulo Elementos",
            link: "elements",
            desc: "Tendrás un QR disponible para cada máquina de tu establecimiento y tus clientes podrán escanearlo y saber que ejercicios se pueden hacer con ella",
            check1: false,
            check2: false,
            check3: true},

            {title: "Módulo Encuestas",
            link: "surveys",
            desc: "Podrás crear encuestas para que tus clientes ayuden a mejorar tu servicio con sus opiniones",
            check1: false,
            check2: false,
            check3: true},
        ],
      countries: [
        { name: "United States", code: "US", currency: "USD" },
        { name: "Argentina", code: "AR", currency: "ARS" },
        { name: "Mexico", code: "MX", currency: "MXN" },
        { name: "Spain", code: "ES", currency: "EUR" },
        // Agrega más países según sea necesario
      ]
    };
  },
  created() {

    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);

    this.country = localStorage.getItem('country');
    if(this.country){
      this.hascountry = true;
    }
    this.getCreditsNow()
    if(this.$router.currentRoute.params.status){
      this.status = this.$router.currentRoute.params.status
    }
    if(this.status == 'success'){
      this.credits = 101;


         this.sessionId = this.$route.query.session_id;
        if(this.sessionId){
          confirmSubscriptionPayment(this.sessionId);
        }
        
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('subscriptionActivated'),
          type: "success",
          position: 'top',
        }).then((result) => {
               this.$router.push(`/dashboard`);
                });
    }

    // Inicialmente, carga el precio en USD (predeterminado)
    this.selectedCountry = this.countries.find(c => c.currency === 'USD');
  },
  methods: {     

    updateCountdown() {
      const now = new Date().getTime();
      const timeLeft = this.targetDate - now;
      
      if (timeLeft > 0) {
        this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      } else {
        this.days = this.hours = this.minutes = this.seconds = 0;
      }
    },
    formatDate(startDate) {
    const date = new Date(startDate);

          // Extraemos el año, mes y día directamente sin realizar conversiones de zonas horarias
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0-11, sumamos 1
          const day = String(date.getDate()).padStart(2, '0');

          // Retornamos la fecha en formato YYYY-MM-DD
          return `${year}-${month}-${day}`;
      
    },
    changeCountry(){
        this.editCountry()
    },
    async editCountry(){
    localStorage.setItem('country',this.country);
      await editUser({country: this.country})
    },
    async getCreditsNow(){
      const { ok,credits,lastPayment } = await getCredits();
      this.credits = credits
      this.lastPayment = lastPayment
      if(this.status == 'success'){
      this.credits = 101
      }
    },
    isMercadoPagoCountry() {
      if(this.isGym()){
        return true;
      }
      const mercadoPagoCountries = [
        "AR",
        "BR",
        "CL",
        "PE",
        "CO",
        "UR",
        "MX",
        "Argentina",
        "Argentine",
        "Brasil",
        "Brazil",
        "Chile",
        "Colombia",
        "México",
        "Mexico",
        "Perú",
        "Peru",
        "Uruguay"
      ];

      // Retorna true si el país está en la lista de países de Mercado Pago
      return mercadoPagoCountries.includes(this.country);
    },
    async getPrice() {
      const response = await getPriceAction(this.selectedCountry.currency);
      this.price = response.data;
    },
    onCountryChange() {
      // Cada vez que el usuario cambie el país, se actualiza el precio
      this.getPrice();
    }
  },
  components: {
    PricingCard,
    MercadoPago,
    StripeButton
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
