<template>
  <div class="md-layout">
    <div class="md-layout-item w-100 bg-white text-primary">
            <div class="image-container logo">
          <img :src="'/img/gotakeit.png'" title="" />
        </div>

      <div class="terms-and-conditions">
           <!-- English Version -->

          

           <collapse
              id="collapsetotal23"
              :collapse="['Términos y Condiciones de Uso']"
              iconlabel="gavel"
              icon="keyboard_arrow_up"
              :open="false"
              class="bg-white p-3">
               <template slot="md-collapse-pane-1">
        <!-- Spanish Version -->
        <div id="terms-es">
          <h1>Términos y Condiciones de Uso</h1>
          
          <h2>1. Introducción</h2>
          <p>
            Estos Términos y Condiciones ("Términos") regulan el uso de la aplicación de menú QR ("la Aplicación") proporcionada por GoTakeIT ("nosotros" o "la Empresa"). Al utilizar la Aplicación, usted ("el Usuario") acepta estar vinculado por estos Términos. Si no está de acuerdo con estos Términos, debe dejar de utilizar la Aplicación de inmediato.
          </p>
          
          <h2>2. Descripción del Servicio</h2>
          <p>
            La Aplicación ofrece una plataforma para crear y gestionar clientes. Los clientes pueden escanear códigos QR para acceder al establecimiento
          </p>
          
          <h2>3. Uso de la Aplicación</h2>
          <ul>
            <li>El Usuario es responsable de asegurarse de que su dispositivo es compatible con la Aplicación y de que dispone de una conexión a internet adecuada.</li>
            <li>Queda prohibido el uso de la Aplicación para actividades ilegales, inmorales o no autorizadas.</li>
          </ul>
          
          <h2>4. Limitaciones de Responsabilidad</h2>
          <ul>
            <li><strong>Relación con los establecimientos comerciales:</strong> La Aplicación no es responsable de la calidad, disponibilidad, precios de los establecimientos y comerciosque la utilicen.</li>
            <li><strong>Contenido de las rutinas:</strong> GoTakeIT no se responsabiliza por la exactitud o veracidad del contenido publicado en las rutinas, ya que esta información es proporcionada y gestionada directamente por los establecimientos.</li>
            <li><strong>Disponibilidad de la Aplicación:</strong> GoTakeIT no garantiza que la Aplicación estará disponible en todo momento ni que estará libre de errores o interrupciones. No asumimos responsabilidad alguna por pérdidas o daños que puedan derivarse de la falta de disponibilidad o fallos de la Aplicación.</li>
          </ul>
          
          <h2>5. Propiedad Intelectual</h2>
          <p>
            Todos los derechos de propiedad intelectual relacionados con la Aplicación y su contenido, incluyendo pero no limitándose a textos, gráficos, logotipos, íconos, imágenes y software, son propiedad exclusiva de GoTakeIT o de sus licenciantes.
          </p>
          <p>
            Queda prohibida la reproducción, distribución, modificación o cualquier otro uso no autorizado del contenido de la Aplicación.
          </p>
          
          <h2>6. Modificaciones a los Términos</h2>
          <p>
            GoTakeIT se reserva el derecho de modificar estos Términos en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en la Aplicación. Se recomienda a los Usuarios revisar periódicamente estos Términos para estar al tanto de cualquier cambio.
          </p>
          
          <h2>7. Privacidad</h2>
          <p>
            El uso de la Aplicación está sujeto a nuestra Política de Privacidad, la cual describe cómo recopilamos, utilizamos y protegemos la información personal del Usuario.
          </p>
          
          <h2>8. Ley Aplicable y Jurisdicción</h2>
          <p>
            Estos Términos se rigen por las leyes argentinas.
          </p>
          
          <h2>9. Contacto</h2>
          <p>
            Si tiene alguna pregunta sobre estos Términos, puede contactarnos a través de nuestro correo electrónico: <a href="mailto:contact@gotake.it">contact@gotake.it</a>.
          </p>
        </div>
        </template>
        </collapse>
   

      </div>
    </div>
  </div>
</template>

<script>
import { Collapse } from "@/components";


export default {
  components: {
    Collapse
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="css">
.terms-and-conditions {
  padding: 20px;
  line-height: 1.6;
}

.terms-and-conditions h1,
.terms-and-conditions h2 {
  color: #333;
}

.terms-and-conditions p,
.terms-and-conditions ul {
  margin-bottom: 20px;
  color: #555;
}

.terms-and-conditions a {
  color: #007bff;
  text-decoration: none;
}

.terms-and-conditions a:hover {
  text-decoration: underline;
}
</style>

