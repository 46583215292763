<template>
  <div class="add-user-form">
      <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100">
                <md-field class="md-form-group">
                  <md-icon>face</md-icon>
                  <label>{{$t('name')}}</label>
                  <md-input 
                    v-model="form.name" 
                    type="text" 
                    @input="clearError('name')" 
                    :required="requiredfields.includes('name')"
                    :placeholder="errors.name?errors.name:''"
                  ></md-input>
                </md-field>

                   
                <md-field class="md-form-group" 
                    :class="errors.email ? 'md-error' : ''">
                    <md-icon>email</md-icon>
                    <label v-if="errors.email" class="md-error"> {{ errors.email }}</label>
                    <label v-else>Email</label>
                    <md-input 
                    v-model="form.email" 
                    type="email" 
                    @input="validateEmail" 
                    :required="requiredfields.includes('email')"
                    ></md-input>
                </md-field>
                   



                 <div class="mt-2" v-if="!hide.includes('identifier') && formrole == 'client'">
                 

                  <div class="my-2 align-left text-primary" v-if="!anonymous">                 
                    <md-icon class="text-primary">chat</md-icon> "<small class="text-secondary">{{$t('accessIdentifierInfo')}} <strong>({{$t('leaveEmptyForEmail')}})</strong></small>"
                  </div>
                  <div class="my-2 align-left text-primary" v-if="anonymous">
                    <md-icon class="text-primary">chat</md-icon> "<small>Utilizarás tu DNI o cualquier identificador para ingresar al gimnasio y ver tu información</small>"
                  </div>
                    <md-field class="md-form-group" 
                        :class="errors.identifier ? 'md-error' : ''">
                        <md-icon>key</md-icon>
                        <label v-if="errors.identifier" class="md-error"> {{ errors.identifier }}</label>
                        <label v-else>DNI o algún identificador</label>
                        <md-input 
                        v-model="form.identifier" 
                        @input="clearError('identifier')" 
                        type="text" 
                        :required="requiredfields.includes('identifier')"
                        ></md-input>
                    </md-field>
                </div>


                <div class="md-layout"  v-if="!hide.includes('imc')">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field class="md-form-group">
                        <md-icon>height</md-icon>
                        <label>{{$t('height')}}</label>
                        <md-select v-model="form.height" >
                          <md-option :value="false">- Vacio -</md-option>
                          <md-option v-for="(item) in altura" :key="item" :value="item">{{ item }} cm</md-option>
                        </md-select>
                    </md-field>
                    <md-field class="md-form-group" >
                        <md-icon>scale</md-icon>
                        <label>{{$t('weight')}}</label>
                        <md-select v-model="form.weight" >
                          <md-option :value="false">- Vacio -</md-option>
                          <md-option v-for="(item) in peso" :key="item" :value="item">{{ item }} kg</md-option>
                        </md-select>
                    </md-field>
                    <md-field class="md-form-group" >
                        <md-icon>person</md-icon>
                        <label>{{$t('Edad')}}</label>
                        <md-select v-model="form.age" >
                          <md-option :value="false">- Vacio -</md-option>
                          <md-option v-for="(item) in age" :key="item" :value="item">{{ item }} años</md-option>
                        </md-select>
                    </md-field>

                    <md-field class="md-form-group"  >
                        <md-icon>wc</md-icon>
                        <label>{{$t('gender')}}</label>
                        <md-select v-model="form.gender" >
                          <md-option :value="false">- Ninguno -</md-option>
                          <md-option value="F">Femenino</md-option>
                          <md-option value="M">Masculino</md-option>
                        </md-select>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-small-size-100">
                   
                    <div class="px-4 float-left">
                    <strong class="text-primary">{{calcularIMC()}}</strong>
                    </div>
                    <div >
                     <img :src="imc"/>
                    </div>

                  </div>
                </div>





                <md-field class="md-form-group" v-if="!hide.includes('password')">
                  <md-icon>lock_outline</md-icon>
                  <label>Password</label>
                  <md-input 
                    v-model="form.password" 
                    type="password" 
                    @input="validatePassword"
                    :required="requiredfields.includes('password')"
                    :placeholder="errors.password?errors.password:''"
                  ></md-input>
                </md-field>
                  <small v-if="errors.passwordpattern" class="error">{{ errors.passwordpattern }}</small>


                <md-field class="md-form-group" v-if="!hide.includes('role')">
                  <md-icon>supervisor_account</md-icon>
                  <label>Role</label>
                  <md-select 
                  v-if="formrole"
                    v-model="formrole" 

                    @md-selected="clearError('role')"
                    :required="requiredfields.includes('role')"
                  >
                    <md-option 
                      v-for="role in roles" 
                      :key="role" 
                      :value="role">
                       {{ $t(role+'_role') }}
                    </md-option>
                  </md-select>
                  <span v-if="errors.role" class="error">{{ errors.role }}</span>
                </md-field>

                <div class="card p-2 mb-2" v-if="config.length > 0 && !anonymous && !hide.includes('paymentType') && form.role == 'client'">

                     
                          <strong>Precargar un plan de pago creado anteriormente</strong>

                          <md-field class="md-form-group" v-if="!hide.includes('config') && config.length">
                            <md-icon>settings</md-icon>
                            <label>Mis planes de pago</label>

                            <md-select  
                              v-model="myconfig_id"  
                              @md-selected="choseConfig()" 
                              class="px-3">
                              <md-option  class="ml-3"
                                v-for="conf in config" 
                                :key="conf._id" 
                                :value="conf._id">
                               <strong v-if="conf.price">{{conf.price}}$<small v-if="conf.paymentType == 'month'"> {{$t('perMonth')}}</small>:</strong> 
                               <small> {{ getPaymentMessage(conf) }} </small>
                               
                              </md-option>
                            </md-select>
                            <span v-if="errors.role" class="error">{{ errors.role }}</span>
                          </md-field>
                          </div>

                <div class="card p-2" v-if="!anonymous && !hide.includes('paymentType') && form.role == 'client'">

                     
                         
                          <strong>Plan de pago</strong>

                             <h5 class="p-2 text-primary text-left">
                        
                          <small>{{ getPaymentMessage({ paymentType, access: unlimitedAccess ? -1 : form.access, cupos: unlimitedQuota ? -1 : form.cupos }) }}</small>
      
                        </h5> 
                        <md-field  class="md-form-group" v-if="paymentType == 'month'">
                          <md-icon>assignment_ind</md-icon>
                          <label>{{$t('paymentDate')}}</label>
                          <md-input 
                            v-model="form.paymentDate" 
                            type="date" 
                            @input="clearError('paymentDate')"
                            :required="requiredfields.includes('paymentDate')"
                            :placeholder="errors.paymentDate?errors.paymentDate:''"
                          ></md-input>
                        </md-field>

                        <md-field class="md-form-group">
                          <md-icon>paid</md-icon>
                          <label>{{$t('paymentMethod')}}</label>
                          <md-select 
                            v-model="form.paymentType" 
                            @md-selected="clearError('paymentType')"
                            :required="requiredfields.includes('paymentType')">
                            <md-option 
                              key="month" 
                              value="month">
                               {{$t('monthlyPayment')}}
                            </md-option>
                            <md-option
                              key="access" 
                              value="access">
                               {{$t('paymentPerAccessOrQuota')}}
                            </md-option>
                          </md-select>
                          <span v-if="errors.paymentType" class="error">{{ errors.paymentType }}</span>
                        </md-field>

                          <div class="md-size-100 pl-2">           
                            <md-field>  
                              <md-icon>paid</md-icon>
                              <label>{{$t('productPrice')}}</label>
                              <md-input
                                min="0" 
                                type="Number" 
                                v-model="form.price" 
                                inputmode="decimal" 
                                @input="clearError('price')"
                              />
                            </md-field>
                          </div>


                         <div class="mb-2">   
                           <div class=" md-size-100 mt-4 pl-2">           
                              <md-icon class="text-primary">chat</md-icon> "<small class="text-secondary">{{$t('allowedAccessCount')}}<span class="text-black" v-if="paymentType == 'month'"> ({{$t('perMonth')}})</span></small>"

                             <md-field class="my-0 py-0" v-if="paymentType == 'month'">
                              <md-checkbox v-model="unlimitedAccess" 
                              @change="clearError('unlimitedAccess')"> 
                                {{ $t('unlimitedAccess') }}
                              </md-checkbox> 
                              </md-field>

                            <md-field v-if="!unlimitedAccess">  
                              <md-icon>meeting_room</md-icon>
                              <label>{{$t('accesses')}}</label>
                              <md-input  
                              min="0" type="text" v-model="form.access" />
                            </md-field>
                          </div>
                        </div>

                         <div class="mb-2">   
                           <div class=" md-size-100 mt-4 pl-2">           
                              <md-icon class="text-primary">chat</md-icon> 
                              "<small class="text-secondary">{{$t('classLimitPerMonth')}}<span  class="text-black"  v-if="paymentType == 'month'"> ({{$t('perMonth')}})</span></small>"
                                
                              <md-field class="my-0 py-0"  v-if="paymentType == 'month'">
                                <md-checkbox v-model="unlimitedQuota" 
                                @change="clearError('unlimitedQuota')">
                                  {{ $t('unlimitedQuota') }}
                                </md-checkbox> 
                              </md-field>
                              <md-field v-if="!unlimitedQuota">  
                                <md-icon>self_improvement</md-icon>
                                <label>{{$t('quota')}}</label>
                                <md-input  
                                min="0" type="text" v-model="form.cupos" />
                              </md-field>
                          </div>        
                        </div>


                      
                        </div>



            </div>
            <div class="md-layout-item md-size-100 md-small-size-100 text-center ">
                <div class="mt-2 align-left text-primary" v-if="!hide.includes('avatar') && this.form.role == 'client' && anonymous">
                 <md-icon class="text-primary" >chat</md-icon> "<small>{{$t('uploadImageMessage')}}</small>"
                </div>
               <div class="file-input img-circle mt-2">
                  <avatar :logo="logo"></avatar>
                  <div class="button-container"  v-if="!isLoading">
                    <md-button
                      class="md-danger md-round"
                      @click="removeImage()"
                      v-if="logo">
                      <i class="fa fa-times"></i>{{$t('remove')}}
                    </md-button>
                    <md-button class="md-success md-round md-fileinput">
                      <template v-if="!logo">{{$t('selectImage')}}</template>
                      <template v-else>{{$t('change')}}</template>
                      <input type="file" accept=".png, .jpg, .jpeg" @change="onLogoChange" />
                    </md-button>
                  </div>
                </div>
              </div>
      </div>



      <div class="loading-container mr-2" v-if="isLoading">
        <md-progress-spinner 
          :md-diameter="20" 
          md-mode="indeterminate" 
          class="md-secondary mr-2">
        </md-progress-spinner>
      </div>
      
      <strong v-if="errorMessage" class="text-danger">{{ $t(errorMessage) }}</strong>
      

    <md-button class="md-button md-small md-primary w-100" :disabled="isLoading" @click="submitForm">{{$t('save')}}</md-button>
          


  </div>
</template>

<script>

import {createUserAction,createUserAnonymousAction } from "@/pages/Users/actions";
import {getPaymentConfigAction} from "@/pages/Auth/actions";
import { uploadImageAction } from "@/pages/Products/actions";
import permissionsMixin from '@/mixins/permissionsMixin';
import Avatar from '@/components/Avatar.vue'; // Ajusta la ruta si es necesario
import Swal from "sweetalert2";


export default {  
  mixins: [permissionsMixin],
  components: {
   Avatar
  },
  props: {
    anonymous: {
      type: Boolean,
      default: () => false,
    },
    roles: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
    },
    required: {
      type: Array,
      default: () => ['name', 'email', 'password', 'role'],
    },
    hide: {
      type: Array,
      default: () => ['identifier','paymentDate'],
    },
    user:{
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isLoading:false,
      myconfig:false,
      imc: process.env.BASE_URL+'img/imc.png',
      config:[],
      altura:[],
      peso:[],
      age:[],
      avatarImg: "/img/placeholder.jpg",
      selectedLogoFile: '',
      logo:'',
      unlimitedAccess:true,
      unlimitedQuota: false,
      formrole:'',
      errorMessage:'',
      paymentType: 'month',
      myconfig_id:false,
      form: {
        requiredfields: false,
        name: '',
        email: '',
        password: '',
        identifier: '',
        role: '',
        phone: '',
        height: '',
        weight: '',
        age: ''
      },
      errors: {},
    };
  },
  computed: {
    todayDate() {
      // Obtén la fecha actual en el formato 'YYYY-MM-DD'
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  created(){

    this.altura = Array.from({ length: 250 }, (_, index) => index + 100);
    this.peso = Array.from({ length: 250 }, (_, index) => index + 10);
    this.age = Array.from({ length: 90 }, (_, index) => index + 10);


    if(this.isGym()){
      this.getPaymentConfig();
    }
    this.requiredfields = this.required;
    this.form.paymentDate = this.todayDate;

    if(!this.hide.includes('role')){
      if(this.type == 'restaurant'){
        this.formrole = 'employee'
        this.form.role = 'employee'
      }
      if(this.type == 'gym' || this.roles.length == 0){
        this.formrole = 'client'
        this.form.role = 'client'
      }
    }

   if(!this.form.paymentType){
    this.form.paymentType = 'month'
   }

    if(this.user && this.user._id){
      this.logo = this.user.avatar
      this.form._id = this.user._id
      if(this.user.paymentDate){
        const today = new Date(this.user.paymentDate); // Convierte la fecha a un objeto Date
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        this.form.paymentDate =  `${year}-${month}-${day}`
      }
      if(this.user.email){
        this.form.email = this.user.email
      }
      if(this.user.name){
        this.form.name = this.user.name
      }
      if(this.user.price){
        this.form.price = this.user.price
      }

      if(this.user.identifier){
        this.form.identifier = this.user.identifier
      }
      if(this.user.phone){
        this.form.phone = this.user.phone
      }
      if(this.user.height){
        this.form.height = this.user.height
      }
      if(this.user.age){
        this.form.age = this.user.age
      }
      if(this.user.weight){
        this.form.weight = this.user.weight
      }
      if(this.user.gender){
        this.form.gender = this.user.gender
      }
      if(this.user.phone){
        this.form.phone = this.user.phone
      }
      if(this.user.cupos){
        this.form.cupos = this.user.cupos
      }
      if(this.user.role){
         this.formrole = this.user.role
        this.form.role = this.user.role
      }

      this.form.access = this.user.access

      if(this.user.paymentType != 'month' && this.user.cupos < 0 ){
        this.user.cupos = 0
      }
      
      this.form.cupos = this.user.cupos
      
      if(this.user.paymentType){
        this.form.paymentType = this.user.paymentType
        this.paymentType = this.user.paymentType
      }

      console.log(this.user)
      if(this.user.access == undefined){
          this.user.access = -1;
          this.form.access = -1;
      } 
      if(this.user.cupos == undefined){
          this.user.cupos = -1;
          this.form.cupos = -1;
      } 

      if(this.user.access < 0){
        this.unlimitedAccess = true;
      }else{
        this.unlimitedAccess = false;
      }
      if(this.user.cupos < 0){
        this.unlimitedQuota = true;
      }else{
        this.unlimitedQuota = false;
      }
    }else{
      if(this.roles[0] && !this.hide.includes('role')){
        this.formrole = this.roles[0];
        this.form.role = this.roles[0];
      }
    }


      this.getPaymentMessage(this.form)

  },
  methods: {
      calcularIMC() {
        // Validación básica de entrada

        let peso = this.form.weight;
        let altura = this.form.height / 100;
        let genero = this.form.gender;

        if(!peso || !altura){
          return '';
        }


        // Calcular IMC
        let imc = peso / (altura * altura); // altura en metros
        imc = imc.toFixed(2); // Redondear a dos decimales

        // Clasificación del IMC según la OMS
        let clasificacion = "";
        if (imc < 18.5) {
            clasificacion = "Bajo peso";
        } else if (imc >= 18.5 && imc < 24.9) {
            clasificacion = "Peso normal";
        } else if (imc >= 25 && imc < 29.9) {
            clasificacion = "Sobrepeso";
        } else if (imc >= 30 && imc < 34.9) {
            clasificacion = "Obesidad grado I";
        } else if (imc >= 35 && imc < 39.9) {
            clasificacion = "Obesidad grado II";
        } else {
            clasificacion = "Obesidad grado III";
        }

        // Mensaje adaptado según género
        let mensajeGenero = genero === "F" ? "mujer" : "hombre";


        // Resultado final
        return `El IMC calculado para una ${mensajeGenero} con un peso de ${peso} kg y una altura de ${altura} m es ${imc}.`;
    },
    choseConfig(){
        this.myconfig = this.config.find(element => element._id === this.myconfig_id);


        this.form.cupos = this.myconfig.cupos
        if(this.myconfig.cupos == -1){
            this.unlimitedQuota = true
        }else{
            this.unlimitedQuota = false
        }
        this.form.access = this.myconfig.access
        if(this.myconfig.access == -1){
            this.unlimitedAccess = true
        }else{
            this.unlimitedAccess = false
        }
        this.form.paymentType = this.myconfig.paymentType
        this.paymentType = this.myconfig.paymentType
        this.form.price = this.myconfig.price
    },
    async getPaymentConfig(){
        const response = await getPaymentConfigAction();
        this.config = response.data
    },
    clearError(field) {


      if(field == 'paymentType' && this.form.paymentType != 'month'){
        this.unlimitedAccess = false;
        this.unlimitedQuota = false;
        if(this.form.cupos == -1){
          this.form.cupos = 0;
        }
        
      }
      this.paymentType = this.form.paymentType

      if(field == 'unlimitedAccess' && this.unlimitedAccess){
        this.form.access = -1;
      }
      if(field == 'unlimitedAccess' && !this.unlimitedAccess){
        this.form.access = 10;
      }
      if(field == 'unlimitedQuota' && this.unlimitedQuota){
        this.form.cupos = -1;
      }
      if(field == 'unlimitedQuota' && !this.unlimitedQuota && this.form.cupos == -1){
        this.form.cupos = 10;
      }

      if(field == 'role'){
        this.form.role = this.formrole
         if(this.form.role == 'gym'){
            this.requiredfields.push('email') 
         }
      }

      if (this.errors[field]) {
        this.$delete(this.errors, field);
      }


    },
    onLogoChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedLogoFile = files[0];
      this.createImagePreview(this.selectedLogoFile, 'logo');
    },
    createImagePreview(file, type) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.logo = e.target.result;  
      };
      reader.readAsDataURL(file);
    },
    removeLogo() {
      this.logo = "";
      this.selectedLogoFile = null;
    },

    removeImage() {
      this.logo = "";
      this.selectedLogoFile = null;
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.form.email)) {
        this.errors.email = this.$t('emailInvalid');
      } else {
        this.clearError('email');
      }
    },
    validatePassword() {
      const password = this.form.password;
      const strongPasswordPattern = /^(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;
      if (!strongPasswordPattern.test(password)) {
        this.errors.passwordpattern = this.$t('password_needs_symbol')+', ' + this.$t('password_too_short');
      } else {
        this.clearError('password');
        this.clearError('passwordpattern');
      }
    },
    formValid(){
      return (Object.keys(this.errors).length === 0);

    },
    submitForm() {
      this.errors = {};
      this.form.avatar = this.selectedLogoFile
      this.requiredfields.forEach(field => {
        if (!this.form[field]) {
          this.errors[field] = `${field} `+this.$t('nameRequired');
            Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text:  `${field} `+this.$t('nameRequired')
                });
        }
      });



      // Check if there are any validation errors
      if (Object.keys(this.errors).length === 0) {
        this.createUser();
      }
    },
    async createUser(){
       
          if(this.isDemo()){
            return;
          }

            this.isLoading = true;
            let imageUrl = '';
            if (this.form.avatar) {
              const response = await uploadImageAction(this.form.avatar);
              if (response.ok) {
                imageUrl = response.data.data.Location; // URL de la imagen subida
                this.form.avatar = imageUrl;

              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t('errorUploadingImage'),
                });

                this.isLoading = false;

                return;
              }
            }

            
            if(this.form.paymentType){
              this.paymentType = this.form.paymentType;
            }else{
              this.paymentType = 'month';
              this.form.paymentType = 'month';
            }

            if(!this.form.identifier){
              this.form.identifier = this.form.email
            }


          try {
            let response;
            if(this.anonymous){
              response = await createUserAnonymousAction(this.form);
            }else{
              response = await createUserAction(this.form);
            }
            if (response.ok) {
                this.isLoading = false;
              this.errorMessage = null;
              this.$emit('userCreated', this.form.identifier);
            } else {
              this.isLoading = false;
              if(response.message == 'emailExistsMessage' || response.message == 'userExists'){
                this.errors.email = this.$t('emailExistsMessage')   
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t('emailExistsMessage')
                });
              }
              if(response.message == 'identifierExistsMessage'){
                this.errors.identifier = this.$t('identifierExistsMessage')  
                 Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t('emailExistsMessage')
                });
              }
              if(response.message == 'User already exists'){
                this.errors.identifier = this.$t('identifierExistsMessage')  
                 Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t('identifierExistsMessage')
                });
              }


            }
          } catch (error) {
                this.isLoading = false;
                this.errorMessage ='userExists';
          }
    },
    getPaymentMessage(conf) {

      let productName = '';
      if (conf.paymentType == 'month' && conf.access == -1 && conf.cupos == -1) {
        productName = this.$t("unlimitedMonthlyPayment");
      } else if (conf.paymentType == 'month' && conf.access == -1 && conf.cupos > -1) {
        productName = this.$t("unlimitedMonthlyPaymentWithQuota", { cupos: this.form.cupos });
      } else if (conf.paymentType == 'month' && conf.access > -1 && conf.cupos == -1) {
        productName = this.$t("limitedAccessUnlimitedQuota", { access: this.form.access });
      } else if (conf.paymentType == 'month' && conf.access > -1 && conf.cupos > -1) {
        productName = this.$t("limitedMonthlyPayment", { cupos: this.form.cupos, access: this.form.access });
      } else if (conf.paymentType != 'month' && conf.access > -1 && conf.cupos > -1) {
        productName = this.$t("oneTimePayment", { cupos: conf.cupos, access: conf.access });
      }
      
      this.form.productName = productName;
   
      return productName;
    }
  },
};
</script>


<style scoped>

.margin-auto{
  margin: 0 auto;
}

.md-input.md-select-value{
      margin-left: 11px !important;
}
.md-helper{
      margin-top: -25px;
    margin-left: 35px;
}
.md-required input::placeholder {
  color: red !important;
  opacity: 1; /* Firefox */
}

.md-required input::-ms-input-placeholder { /* Edge 12 -18 */
  color: red;
}
.md-field label.text-danger {
    color: red !important
}
.md-form-group {
  margin-bottom: 20px;
}
.error {
  color: red;
  font-size: 12px;
}
.md-menu.md-select {
        padding-left: 12px;
}

</style>
