<template>

    <div class="md-layout-container">
  <div class="md-layout-item">
    <FormValidate
    :inputs="formInputs"
    :requiredFields="['title','question','startedAt']"
    :buttonLabel="$t('save')"
    :formTitle="titleForm"
    @formSubmitted="handleFormSubmit"
    @cancel="cancel()">
    </FormValidate>

      <modal v-if="classicModal" @close="classicModalHide">
        
        <template slot="header">
          <div class="p-4">
            <h4 class="modal-title">{{classicModal.title}}</h4>
            <p>{{classicModal.question}}</p>
            <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
              <md-icon>clear</md-icon>
            </md-button>
          </div>
        </template>

        <template slot="body">
          <span v-if="classicModal.survey_answers == 0">No hay respuestas</span>
          <div v-else>
            <ul>
            <li v-for="answer in classicModal.survey_answers">{{answer.answer}}</li>
            </ul>
          </div>
        </template>

        <template slot="footer">
          
        </template>
      </modal>
    </div>
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title mt-3">Encuestas</h4>
          <p class="category">Here is a subtitle for this table</p>
        </md-card-header>
        <md-card-content>
        
          <div class="w-100 text-center customborder" v-if="surveys.length == 0">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>

          <md-table v-model="surveys">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">{{ item.title }}</md-table-cell>
              <md-table-cell md-label="Pregunta">{{ item.question }}</md-table-cell>
              <md-table-cell md-label="Dueño">{{ item.owner.name }}</md-table-cell>
              <md-table-cell md-label="Fecha de inicio">{{ new Date(item.startedAt).toLocaleDateString() }}</md-table-cell>
              <md-table-cell md-label="Estado">
                      <md-switch v-model="item.status" @change="onOffSwitch(item)">
                  <span v-if="!item.status">Desactivado</span>
                  <span v-else>Activado</span>
                  <md-icon :class="item.status ? 'text-success':''">circle</md-icon>
                </md-switch>
              </md-table-cell>
              <md-table-cell md-label="Respuestas"><span class="cursor-pointer" @click="openModal(item)">Ver respuestas</span></md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { createOrUpdateSurvey, getSurveys } from "@/pages/Surveys/actions";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';
import  FormValidate from '@/components/FormValidate.vue';
import { Modal } from "@/components"; // Importa el componente modal


export default {
  mixins: [permissionsMixin],
  components: {
    FormValidate,
    Modal
  },

  name: "Stripe",

  data() {
    return {
      titleForm: 'Crear encuesta',
      formInputs: [
        { name: 'title', label: this.$t('name'), type: 'text' },
        { name: 'question', label: 'pregunta', type: 'text' },
        { name: 'startedAt', label: 'comienzo', type: 'date' }
      ],
      classicModal: false,
      surveys:[],
      switchValue: []
    };
  },
  created() {
    this.loadinit();

  },
  methods: {
    async loadinit(){
      const response = await getSurveys();
      this.surveys = response.surveys;
    },
    async handleFormSubmit(formData) {
        formData.status = true;
        await createOrUpdateSurvey(formData);
        this.loadinit()
    },
    async onOffSwitch(item){
        await createOrUpdateSurvey(item);
    },
    openAnswers(){

    },
    openModal(item){
      this.classicModal = item;
    },
    classicModalHide() {
      this.classicModal = false;
    },
  },
};
</script>