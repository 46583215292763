<template>
 <div>

  <survey v-if="isClientGym()"></survey>

  <div class="row px-2 mb-1" v-if="!isDemoGym()">
    <a href="/subscribe" class="col-12">  
      <md-button class="md-primary w-100 cursor-pointer"  v-if="isGym() && isDemoGym()">  
       <md-icon>diamond</md-icon> Ver planes de suscripción  
      </md-button>
    </a>
  </div>

  <div  class="alert alert-primary alert-with-icon"
  v-if="false"
    data-notify="container">
    <i data-notify="icon" class="material-icons">computer</i>
    <span data-notify="message">02/11/2024: Ahora se generará una orden de pago cuando marques un cliente como pagado. Eso nos permitirá generar gráficos con estadísticas financieras para vos en el futuro</span>
  </div>

  <ClientDashboard v-if="isClientGym()"></ClientDashboard>
            

  <div class="md-layout" v-if="!isClientGym()">


    <div class="md-layout-item  md-size-50 md-small-size-100 p-2" v-if="isGym() || isTeacher()">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>today</md-icon>
          </div>
          <h4 class="title">{{ $t('addDailyActivityTitle') }}</h4>
          <p class="px-2 pt-3 md-text text-secondary">
            <md-icon>chat</md-icon> "{{ $t('shareScheduleInfo') }}"
           
            
          </p> <router-link class="md-primary text-primary" :to="{ path: 'schedule'}">  
          <small class="ml-2">
                  <md-icon  class="text-primary">link</md-icon> 
                  <span class="text-primary">https://{{subdomain}}/schedule</span>
          </small></router-link> 

        </md-card-header>

        <md-card-content>

        <ScheduleWeekGym v-if="token"/>
        </md-card-content>
      </md-card>
    </div> 


    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50 p-2" v-if="isGym()">
      <TopUsers/> <ingressByUser/>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100 p-2" v-if="isGym()">
      <Payments/>
    </div> 
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50 p-2" v-if="isGym()">
      <QrBLock @selected="QrSelected($event)" @label="QrLabel($event)"/>
    </div>

  <Settings :dashboard="true" v-if="isRestaurant()"></Settings>


    
  

    <div class="md-layout-item md-medium-size-50 md-small-size-100 md-xsmall-size-100 md-size-50 p-2 position-relative" v-if="isRestaurant() && qrselected">
      <stats-card header-color="rose" style="background-color: #e1eaf1;">
        <template slot="header">
          <div class="card-icon">
            <md-icon>qr_code</md-icon>
          </div>
          <span class="category">{{$t('viewMenu')}}</span>
          <h3 class="title">
           {{ qrselected.name }} - {{qrselected.menu.name}}
          </h3>
        </template>
        <template slot="content">
           <div>
             <div class="iphonecontainer ">
              <div class="iphone iphoneleft"></div>
              <div class="iphone iphoneright"></div>
              <div class="iphone iphonetop"></div>
              <div class="iphone iphonebottom"></div>
              <AnonymousOrder id="anonymousorder" :argqrId="qrselected._id" :key="qrselected._id"></AnonymousOrder>
             </div>
           </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50 p-2" v-if="isRestaurant() && hasPermission('StablishmentBLock')">
      <StablishmentBLock/>
    </div>

    

    <div class="w-100">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50 p-2" v-if="hasPermission('sells')">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>account_balance_wallet</md-icon>
          </div>
          <p class="category">{{$t('avgOrderValue')}}</p>
          <h3 class="title" v-if="currency">
            <animated-number  v-if="averageOrderValue>0" :value="averageOrderValue"></animated-number> {{currency}}
          </h3>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>local_offer</md-icon>
          </div>
        </template>
      </stats-card>
    </div>


      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50 p-2" v-if="hasPermission('sells')">
        <stats-card header-color="rose">
          <template slot="header">
            <div class="card-icon">
              <md-icon>account_balance_wallet</md-icon>
            </div>
            <p class="category">{{$t('sales')}}</p>
            <h3 class="title" v-if="currency">
              <animated-number  v-if="totalSales>0" :value="totalSales"></animated-number> {{currency}}
            </h3>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>local_offer</md-icon>
            </div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-100 p-2" v-if="isRestaurant() && mostOrderedProductsChart.data.labels.length > 0">
        <stats-card header-color="primary">
          <template slot="header">
            <div class="card-icon">
              <md-icon>credit_card</md-icon>
            </div>
            <h4 class="title">{{$t('topCustomers')}}</h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item">
                <div v-for="(label, index) in topCustomers" :key="index" class="legend-item mt-2">
                  {{ label._id }}    <strong>{{label.totalOrders}}</strong>
                </div>
              </div>
            </div>
          </template>
        </stats-card>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-100 p-2" v-if="isRestaurant() && mostOrderedProductsChart.data.labels.length > 0">
        <stats-card header-color="primary">
          <template slot="header">
            <div class="card-icon">
              <md-icon>shopping_cart</md-icon>
            </div>
            <h4 class="title">{{$t('topOrderedProducts')}}</h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item">
                <div v-for="(label, index) in mostOrderedProductsChart.data.labels" :key="index" class="legend-item mt-2">
                  {{ label }} 
                  <strong>{{mostOrderedProductsChart.data.series[index]}}</strong>
                </div>
              </div>
            </div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode-vue';

import MercadoPagoOauth from "@/pages/Dashboard/Components/MercadoPagoOauth.vue";

import { getSettingsAction } from "@/pages/Auth/actions";
import { QrBLock,StablishmentBLock,AnonymousOrder } from "@/pages";
import ClientDashboard from "@/pages/Dashboard/ClientDashboard.vue"
import permissionsMixin from '@/mixins/permissionsMixin';
import { getMostOrderedProducts, getTopCustomers, getTotalSales, getAverageOrderValue, getOrderStatusCounts } from "@/pages/Dashboard/actions";

import { Settings } from "@/pages";
import { IngressByUser,TopUsers,Payments } from "@/pages/Statistics";


import {
  StatsCard,
  ChartCard,
  ScheduleWeekGym,
  ProductCard,
  AnimatedNumber,
  Survey
} from "@/components";

export default {
  mixins: [permissionsMixin],
  data() {
    return {
      token: false,
      activities:[],
      currency: false,
      mostOrderedProductsChart: {
        data: { labels: [], series: [] },
        options: {
          labelInterpolationFnc: function(value) {
            return value[0];
          }
        },
      },
      subdomain:false,
      qrselected:false,
      topCustomers: [],
      totalSales: null,
      averageOrderValue: null,
      orderStatusCounts: [],
      stablishments:[],
      product1: "/img/card-2.jpg",
      product2: "/img/card-3.jpg",
      product3: "/img/card-1.jpg",
      seq2: 0,
      smallAlertModal:true,
      mapData: {
        AU: 760,
        BR: 550,
        CA: 120,
        DE: 1300,
        FR: 540,
        GB: 690,
        GE: 200,
        IN: 200,
        RO: 600,
        RU: 300,
        US: 2920,
      },
    };
  },
  components: {
    StatsCard,
    AnimatedNumber,
    QrBLock,
    ScheduleWeekGym,
    StablishmentBLock,
    AnonymousOrder,
    ClientDashboard,
    QrcodeVue,
    IngressByUser,
    Payments,
    TopUsers,
    MercadoPagoOauth,
    Settings,
    Survey
  },
  async created() {

    this.token = localStorage.getItem('token');

    this.subdomain = window.location.hostname;
  
    const period = "month"; // o "month", según lo que necesites


    if(this.isRestaurant()){
      await this.fetchMostOrderedProducts();
      const customersResponse = await getTopCustomers(period);
      if (customersResponse.ok) {
        this.topCustomers = customersResponse.data;
      } else {
        console.error(customersResponse.message);
      }
      const salesResponse = await getTotalSales(period);
      if (salesResponse.ok) {
        this.totalSales = salesResponse.data.totalSales;
      } else {
        console.error(salesResponse.message);
      }
      const avgOrderValueResponse = await getAverageOrderValue(period);
      if (avgOrderValueResponse.ok) {
        this.averageOrderValue = avgOrderValueResponse.data.avgOrderValue;
      } else {
        console.error(avgOrderValueResponse.message);
      }

      const statusCountsResponse = await getOrderStatusCounts();
      if (statusCountsResponse.ok) {
        this.orderStatusCounts = statusCountsResponse.data;
      } else {
        console.error(statusCountsResponse.message);
      }

    }
    if(this.isGym() || this.isRestaurant() || this.isTeacher()){
      await this.fetchBusinessData();
    }



  },
  methods:{
    QrSelected(event){
        this.qrselected = event;
    },
    async fetchBusinessData() {
        try {
          const response = await getSettingsAction();
          const data = response.result;
          this.currency = data.currency || "$";
        } catch (error) {
          console.error("Error fetching business data:", error);
        }
    },
    async fetchMostOrderedProducts() {
      try {
        const period = "month"; // or "month", según lo que necesites
        const response = await getMostOrderedProducts(period);

        if (response.ok) {
          const labels = response.data.map(item => item.name || 'No Name');
          const series = response.data.map(item => item.totalQuantity || 0);
          this.mostOrderedProductsChart.data = { labels, series };
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Error fetching most ordered products: ", error);
      }
    },
  
  },
};
</script>
<style>
.cursor-pointer{
   cursor:pointer !important;
}
</style>

<style scoped>
#qrselected{
      position: absolute;
    top: 0;
    right: 0;
}
.qrcode{
  width:100px !important;  
}
  .withiphone{
    width: 350px;
    margin-left: 29px;
    height: 763px;
    overflow-y: hidden;
    padding-top: 22px;
    border-radius: 38px;
  }
  .iphone {
    background:url('/public/img/iphone.png');
    position: absolute;
    z-index: 9;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .iphoneleft {
 width: 39px;
    height: 742px;
    left: 7px;
    top: 1px;
    background-size: 483px;
    background-position: -37px -2px;
  }
  .iphoneright {
    width: 39px;
    height: 742px;
    right: -2px;
    top: 1px;
    background-size: 483px;
    background-position: -400px -1px;
  }
  .iphonetop {
left: -28px;
    width: 470px;
    height: 46px;
    background-position: 0px 3px;
    top: -4px;
  }
  .iphonebottom {
bottom: -13px;
    width: 475px;
    height: 46px;
    background-position-y: -733px;
    left: -30px;
    background-size: cover;
  }
  .iphonecontainer {
 margin: 0 auto;
    width: 399px;
    height: 776px;
    padding: 0px;
    position: relative;
    overflow: hidden;
}

@media (max-width: 440px) {
.iphonecontainer {
  margin-left:-37px;
  margin-top:20px;
}
}
</style>
