<template>
  <div class="w-100 float-left  "
    :class="(itemstatus == 'finished' || !itemstatus) ? 'border-primary':'border-success border-2'">

      
      <div v-if="isStarted"> 
        <md-icon class="float-left  circlesuccess" v-if="itemstatus == 'ongoing'">
        circle</md-icon><small  v-if="itemstatus == 'ongoing'" class="text-success"> Ejercitando...
        </small>
            
            <md-button  class="md-button md-success w-100" 
              @click="updateStatus('ongoing')"
              v-if="itemstatus != 'ongoing'">
              <md-icon>directions_run</md-icon> Comenzar {{item.name}}
            </md-button> 

      </div>

      <div class="card  p-1 text-primary" v-if="item.history.length">
        <div>
            <small class="text-black cursor-pointer">Mi historial {{item.name}}<md-icon class="text-black">history</md-icon></small>
            <TrainingHistory v-for="item in item.history" :history="item"/>
        </div>
      </div>


    <md-table 
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table  paginated-table-exercises  mb-4 theadnone d-none d-sm-block"
    >
      <md-table-row 
        slot="md-table-row"  
         v-for="(item,index) in myExercises"
      >
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
           <div  class="p-2 float-left width56" v-if="source == 'custom' && !isStarted">
            <small class="cursor-pointer p-2" v-if="index > 0"  @click="moveExerciseUp(index)"> 
              <md-icon class="text-primary">arrow_upward</md-icon>
            </small>
            <small class="p-2" v-if="index < (exercises.length-1)"  @click="moveExerciseDown(index)"> 
              <md-icon class="cursor-pointer  text-primary">arrow_downward</md-icon>
            </small>
          </div>
          <div class="">
            <div class="float-left">
              <img :src="getImage(item.image)" loading="lazy" width="90px"/> 
            </div>
            <div>
              <div>
              <strong>{{ item.name }}</strong>
              </div>
              <div class="ml-2 float-left">
               <div class="w-100">
                <span class="py-1" v-if="item.bodyZone.length">{{ item.bodyZone.join(', ') }}</span>
                </div>
                  <span v-if="item.sets && source !== 'custom'" class=" text-secondary">{{item.sets}} series</span>
                  <div class="" v-if="source !== 'custom'">
                    <small class="d-block text-secondary " v-if="item.repetitions.length > 0" >Repeticiones: {{item.repetitions.join(',')}}   </small>
                    <small class="d-block text-secondary" v-if="item.weight && item.duration.length > 0 && item.duration[0] > 0" > Duración: {{item.duration.join(',')}} min</small>
                    <small class="d-block text-secondary" v-if="item.distance" >distancia:  {{item.distance}} m</small>
                    <small class="d-block text-secondary" v-if="item.weight && item.weight.length > 0 && item.weight[0] > 0" > Peso: {{item.weight.join(',')}} </small>
                    <small class="d-block" v-if="item.speed" >velocidad: {{item.speed}} km/h</small>
                    <small class="d-block text-secondary" v-if="item.rest && item.rest.length > 0 && item.rest[0] > 0" > Descanso: {{item.rest.join(',')}} seg</small>
                  </div>
              </div>
            </div>
          </div>
        </md-table-cell>
        <md-table-cell class="position-inherit">
          <div class="float-right">
            <EditMyExercises 
            :status="'itemstatus'" 
            :source="source"  
            :myExercise="item" 
            @updateTraining="updateTraining($event,index)"/>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>   


    <div 
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="d-block d-sm-none myexercises"
    >
      <div v-for="(item,index) in myExercises" class=" card bg-white  float-left p-2 my-2 w-100">
          <div  class="p-2 float-right width56" v-if="source == 'custom'  && !isStarted">
            <small class="cursor-pointer p-2" v-if="index > 0"  @click="moveExerciseUp(index)"> 
              <md-icon class="text-primary">arrow_upward</md-icon>
            </small>
            <small class="p-2 float-right" v-if="index < (exercises.length-1)"  @click="moveExerciseDown(index)"> 
              <md-icon class="cursor-pointer  text-primary">arrow_downward</md-icon>
            </small>
          </div>
          <div>
                <div class="w-100 text-center mb-2">
                  <h5>{{ item.name }}</h5>
                </div>
              <div>
                <div class="pl-3 float-left mb-2 w-100">  
                
                <div class="my-3">
                  <div class="float-left" v-if="item.image">
                    <img :src="getImage(item.image)" loading="lazy" width="90px"/> 
                  </div>
                  <small class="py-1" v-if="item.bodyZone.length"><strong>{{ item.bodyZone.join(', ') }}</strong></small>
                 
                      <div class="mt-2">
                        <EditMyExercises 
                          v-if="source=='custom'" 
                          :status="itemstatus" 
                          :source="source"
                          :myExercise="item" 
                          @updateTraining="updateTraining($event,index)"/>
                      </div>
                   
                </div>

                  <div  v-if="source !== 'custom'">
                    <span v-if="item.sets" class="text-secondary">{{item.sets}} series</span>
                    <small class="d-block text-secondary " v-if="item.repetitions.length > 0" >Repeticiones: {{item.repetitions.join(',')}}   </small>
                    <small class="d-block text-secondary" v-if="item.weight && item.duration.length > 0 && item.duration[0] > 0" > Duración: {{item.duration.join(',')}} min</small>
                    <small class="d-block text-secondary" v-if="item.distance" >Distancia:  {{item.distance}} m</small>
                    <small class="d-block text-secondary" v-if="item.weight && item.weight.length > 0 && item.weight[0] > 0" > Peso: {{item.weight.join(',')}} </small>
                    <small class="d-block" v-if="item.speed" >Velocidad: {{item.speed}} km/h</small>
                    <small class="d-block text-secondary" v-if="item.rest && item.rest.length > 0 && item.rest[0] > 0" > Descanso: {{item.rest.join(',')}} seg</small>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>  

            <button 
              v-if="isStarted && itemstatus == 'ongoing'"
             class="btn-danger btn   w-100 mb-4"
              @click="updateStatus('finished')"
               v-else>
              <md-icon class="text-white">thumb_up</md-icon> Listo Finalizar ({{item.name}})
            </button>    

    </div>
  </div>
</template>

<script>
import { getAllExercisesAction, deleteExerciseAction ,getAllExercisesOfTrainingAction ,updateTrainingExercisesAction, updateUserPlanningAction, updateTrainingExercisesStatusAction} from '@/pages/Training/actions';
import CreateExercise from './CreateExercise.vue'; // El formulario de edición
import EditMyExercises from './EditMyExercises.vue'; // El formulario de edición
import permissionsMixin from '@/mixins/permissionsMixin';
import TrainingHistory from '@/components/TrainingHistory.vue';

import Swal from 'sweetalert2';

export default {  
  mixins: [permissionsMixin],
  data() {
    return {
      exercises: [],
      itemstatus: false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      mobile: false, // Se puede configurar según sea necesario
      selectedExercise: null // Ejercicio seleccionado para editar
    };
  },
  props:{
    trainingId: String,
    myExercises:{},
    item:Object,
    isStarted:Boolean,
    source:String
  },
  components: {
    CreateExercise,
    EditMyExercises,
    TrainingHistory
  },
  async created() {
    this.itemstatus = this.item.status
    this.exercises = this.myExercises
  },
  methods: {
    isFinished(){
      return this.itemstatus=='finished'
    },
    getImage(img) {
      if (!img.includes("gym.gotake.it")) {
          return img.replace("gotake.it", "gym.gotake.it");
      }
      return img;
    },
    updateTraining(exercisenew,index){
      this.exercises[index] = exercisenew
      this.updateAll()
    },
    async updateStatus(status){

        if(status == 'finished'){

            const result = await Swal.fire({
              title: 'Bien hecho!',
              text: `Se generará un historial de los valores que registraste en este grupo de ejercicios. Cuando refresques la pantalla podrás ver tu historial en la pestaña "Mi historial" abajo de cada grupo de ejercicios`,
              icon: 'success',
              showCancelButton: true,
              confirmButtonText: 'Genial',
              cancelButtonText: this.$t('cancel')
            });
        }

        const response = await updateTrainingExercisesStatusAction(
          this.trainingId,status,this.item
        );
        this.itemstatus = status;
        this.$emit("updateTraining")
    },
    async updateAll(){
        const response = await updateTrainingExercisesAction(
          this.trainingId,
          this.exercises
        );
        this.$emit("updateTraining")
    },
    moveExerciseUp(index) {
      const temp = this.exercises[index];
      this.exercises.splice(index, 1);
      this.exercises.splice(index - 1, 0, temp);

      this.updateAll()
    },
    moveExerciseDown(index) {

      const temp = this.exercises[index];
      this.exercises.splice(index, 1);
      this.exercises.splice(index + 1, 0, temp);

  
      this.updateAll()
    },
    
    editExercise(exercise) {
      this.selectedExercise = exercise;
    },
    async confirmDelete(exercise) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the exercise "${exercise.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteExercise(exercise._id);
      }
    },
    async deleteExercise(id) {
      try {
        const response = await deleteExerciseAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Exercise deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de ejercicios
     
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete exercise: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting exercise:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onExerciseUpdated() {
      // Actualiza la lista de ejercicios después de la edición
      this.selectedExercise = null;

    }
  }
};
</script>

<style>
.myexercises{
  
}
.width56{
  width:56px;
}
</style>

<style>
    .circlesuccess {
      color: green;
      animation: blink 1s infinite;
    }

    @keyframes blink {
      0%, 100% {
        color: green;
      }
      50% {
        color: white;
      }
    }
.myexercises .md-theme-default{
  display:contents !important;
}
.position-inherit {
  position:inherit;
}
</style>
