<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-rose">
      <div class="card-icon">
        <md-icon>group</md-icon>
      </div>
      <router-link class="md-primary" :to="{ path: '/qrs'}">
        <h4 class="title">Mi equipo</h4>
      </router-link>
    </md-card-header>
    
    <md-card-content>

    <div>
        <p class="p-2 md-text text-secondary" >
            <md-icon>chat</md-icon> "Si tienes profesores que te gustaría darles acceso al sistema pero sin que tengan acceso administrador, entonces puedes crear aquí sus usuarios. Ellos podrán crear rutinas y asignarlas a los alumnos sin el acceso al resto de funcionalidades sensibles, como listado de clientes, facturción, etc."
        </p>


      <Gym v-if="isGym()" 
        :users="users" 
        :role="['gym','teacher']" 
        :mobile="isMobile" 
        :showFilters="false"
        @refresh="getAll()"/>          
        <div class="w-100 text-center customborder" v-if="users.length == 0">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>

    </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { getAllAdminAction } from "@/pages/Users/actions";


import { Restaurant, Gym, GymMobile } from "@/pages/Users";
import permissionsMixin from '@/mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  components: {
    Restaurant,
    GymMobile,
    Gym
  },
  data() {
    return {
      isMobile: false,
      users:[]
    };
  },
  created(){
    this.getAll();
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768; // Detecta si es un dispositivo móvil (puedes ajustar el ancho según tus necesidades)
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async getAll() {
      try {
        const response = await getAllAdminAction();
        this.users = response.users;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>


