import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

export const saveAction = async (params) => {
  try {
    const { data } = await wencesApi.post("/api/business/save", {
      params
    });

    return {
      ok: true
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Usuario o contraseña incorrectos",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getSettingsAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/business`);

    return {
      ok: true,
      result: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getAdminAction = async () => {
  try {
    const { data } = await wencesApi.get(`/api/admin`);

    return {
      ok: true,
      result: data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Menu no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


export const getDbInfoAction = async (name) => {
  try {
    const { data } = await wencesApi.post("/api/admin/getinfo", {
      name
    });

    return {
      data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Usuario o contraseña incorrectos",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getPaymentConfigAction = async (name) => {
  try {
    const { data } = await wencesApi.post("/api/paymentconfig", {
      name
    });

    return {
      data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Usuario o contraseña incorrectos",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

export const getAllPaymentsAction = async (name) => {
  try {
    const { data } = await wencesApi.post("/api/paymentconfig/payments", {
      name
    });

    return {
      data
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Usuario o contraseña incorrectos",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};
