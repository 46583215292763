<template>
  <div class="md-layout p-4">
    <md-card class="md-card-nav-tabs">
    <md-card-content>
    <div class="md-layout-item">
      <h4>Como funciona GoTakeIT</h4>
      <ul>
        <li  @click="addClients()"><a class="cursor-pointer text-primary">¿Cómo cargar mis clientes?</a><p>Los clientes son los usuarios que tendrán un acceso mediante un identificador y sin necesidad de contraseña, y deberán escanear el QR que pegarás en la puerta para que quede registrado su acceso a tu establecimiento.</p></li>
        
        <li  @click="addClass()"><a class="cursor-pointer text-primary">¿Cómo crear clases?</a><p>Las clases son eventos o actividades que podrás insertar dentro de un calendario donde tus clientes podrán inscribirse.</p></li>
        
        <li  @click="addTeacher()"><a class="cursor-pointer text-primary">¿Cómo crear un usuario para uno de los profesores?</a> <p>Crear un usaurio con Rol 'profesor' te permite darle accesos al sistema a tu empleados sin que puedan modificar secciones sensibles que solo pueden ser trabajadas por un rol "admin".</p></li>
        
        <li  @click="addRutina()"><a class="cursor-pointer text-primary">¿Cómo crear rutinas?</a> <p>Las rutinas permiten a tus clientes tener una guia a la hora de hacer ejercicios. Estas pueden ser creadas por el perfil admin o el profesor. Y podrán ser asignadas a cualquier cliente/alumno.</p></li>
        
        <li  @click="assignClient()"><a class="cursor-pointer text-primary">¿Cómo asignar una rutina a un alumno?</a><p>Puedes asignar turinas a tus clientes para que ellos la tengan en su perfil y puedan comenzar a utilizarla. Ellos también pueden elegir las rutinas públicas y utilizarlas cuando quieran.</p></li>
        
        <li  @click="payments()"><a class="cursor-pointer text-primary">¿De que forma puedo automatizar los cobros a mi clientes?</a><p>Hay dos formas de automatizar los cobros. 1- Sincronizando tu mercado pago con nuestro sistema y dejar que el sistema se encargue de cobrarles a tus clientes. 2- Cargando datos bancarios o link y el sistema se encargará de pedirles capturas de pantalla o foto del comprobante de pago a tus clientes.</p></li>
        
        <li  @click="markAsPaid()"><a class="cursor-pointer text-primary">¿Cómo marco como "pagado" un cliente de forma manual si decido no automatizarlo?</a><p>Un cliente puede marcarse como pagado directamente en el listado de mis clientes.</p></li>

        <li><strong>¿Qué es una solicitud de pago?</strong><p>Debes configurar el precio de lo que paga cada uno de tus clientes, entonces el sistema generará las solicitudes de pago cada mes. Sin una solicitud de pago no es posible automatizar el sistema de cobros.</p></li>


        <li><strong>¿Dónde puedo ver los participantes de un evento?</strong><p>Debes ir al calendario, buscar la actividad y hacer click en el botón verde "PARTICIPANTES", si no hay inscriptos verás un boton verde "NO HAY INSCRIPTOS"</p></li>

        <li><strong>¿Cuál es la diferencia entre accesos y cupos?</strong><p>Accesos es la cantidad de veces que un cliente puede acceder a tu establecimiento por mes, cada vez que escanee el QR se le consumirá un acceso. Para evitar problemas en el conteo, si tu cliente escanee el QR dos veces en un día entonces solo cuenta como 1 acceso. Los cupos es la cantidad de veces que puede inscribirse tu cliente a una actividad/clase.</p></li>

        <li><strong>¿Para que sirve que haya la posiblidad de crear varios QR?</strong><p>La idea es que puedas pegar un QR distinto en cada entrada para el caso donde tu establecimiento tenga diferentes accesos y así poder detectar por donde se ingresó.</p></li>


        <li><strong>¿De cuantas formas un cliente puede pagar en el sistema?</strong><p>Hay 3 formas, en efectivo, tu marcas el cliente como pagado manualmente. Por mercado pago, el sistema lo marca automáticamente como pagado. Por transferencia o link, el sistema pide subir el comprobante para marcarlo como pagado.</p></li>


        <li><strong>¿Cómo hace mi cliente para ingresar al momento de escanear el QR?</strong><p>Tu cliente, al momento de escanear el QR, deberá iniciar sesión con el identificador que tu le hayas definido al crear el usuario, una vez ingresado el identificador, el sistema guardara ese ingreso. La próxima vez que escanee el QR con su teléfono, el sistema no le pedirá que inicie sesión, sino que ya sabrá que es él porque el identificador quedará guardado en su teléfono.</p></li>

      </ul>
    </div>
    </md-card-content>
  </md-card>
  </div>
</template>

<script>
/* eslint-disable no-new */
import permissionsMixin from '@/mixins/permissionsMixin';

import introJs from 'intro.js';
import 'intro.js/introjs.css';


export default {

  mixins: [permissionsMixin],
  created() {
  },
  data() {
  },
  methods: {   

    payments() {
        this.$router.push("/payments").then(() => {
          setTimeout(() => {
          let  elements = document.querySelectorAll('#collapsetotal23 .md-checkbox');

              introJs()
                .setOptions({
                   showProgress: false,
                  disableInteraction: false,
                  showBullets:false,
                  nextLabel: 'Siguiente',
                  prevLabel: 'Atrás',
                  doneLabel: 'Listo',
                  steps: [
                    {
                      element: document.querySelectorAll('.md-card-content .card')[0],
                      title: 'Período de espera',
                      intro: 'Aquí podrás definir cuantos días esperar antes de mostrar al cliente un cartel rojo si su cuota ha vencido cuando escanee el QR al ingresar al establecimiento.',
                    },
                    {
                      element: document.querySelectorAll('.md-card-content .card')[1],
                      title: 'Enviar un email de recordatorio',
                      intro: 'El sistema enviara emails al cliente avisando cuando la fecha de vencimiento del pago está cerca o ha vencido invitandolo a realizar el pago.',
                    },
                    {
                      element: document.querySelectorAll('.md-card-content .card')[4],
                      title: 'Con Mercado Pago',
                      intro: 'Sincroniza tu mercado pago y el sistema se encargará de cobrarle a tus clientes. El dinero llegará directo a tu MercadoPago sin intermediarios',
                    },
                    {
                      element: document.querySelectorAll('.md-card-content .card')[5],
                      title: 'Con link o transferencia',
                      intro: 'El sistema le pedirá a tu cliente que suba el comprobante de pago, cuando el cliente lo suba, el sistema marcara el mes como "pagado". Luego podrás verificar los comprobantes en el listado de clientes opción "comprobantes" para chequear que sean correctos.',
                    }
                  ],
                })
                .start();
          
          }, 500); // Ajusta el retraso si es necesario
        });
    },
    markAsPaid() {
        this.$router.push("/users").then(() => {
          setTimeout(() => {
          let  elements = document.querySelectorAll('#collapsetotal23 .md-checkbox');

              introJs()
                .setOptions({
                   showProgress: false,
                  nextLabel: 'Siguiente',
                  showBullets:false,
                  prevLabel: 'Atrás',
                  doneLabel: 'Listo',
                  steps: [
                    {
                      title: 'Hola👋',
                      intro: 'Comencemos haz click en el boton "siguiente"',
                    },
                    {
                      element: document.querySelector('.markaspaid'),
                      title: 'Marcar como pagado',
                      intro: 'El sistema generará una solicitud de pago ya aprobada y agregará un mes más a la fecha de pago limite del cliente selecciondado.',
                    }, 
                    {
                      element: document.querySelector('.assignplan'),
                      title: 'Plan de pagos',
                      intro: 'Para que el sistema sepa cual es el monto a cobrar, debés asignarle un plan a tu cliente.',
                    }, 
                    {
                      element: document.querySelector('.edituser'),
                      title: 'Asignar plan de pagos',
                      intro: 'Para asignar un plan de pagos solo debes editar el usuario y asignar el precio que paga por mes.',
                    }, 
                  ],
                })
                .start();
          
          }, 500); // Ajusta el retraso si es necesario
        });
    }, 
    addClass() {
        this.$router.push("/dashboard").then(() => {
          setTimeout(() => {
          let  elements = document.querySelectorAll('#collapsetotal23 .md-checkbox');

              introJs()
                .setOptions({
                   showProgress: false,
                   showBullets: false,
                  nextLabel: 'Siguiente',
                  prevLabel: 'Atrás',
                  doneLabel: 'Listo',
                  steps: [
                    {
                      element: document.querySelector('#collapsetotal23'),
                      title: 'Haz click en Anadir Actividad',
                      intro: 'Cuando se abra el formulario haz click en "Siguiente" para seguir la explicación',
                    },
                    {
                      element: elements[0],
                      intro: 'Podrás elegir una fecha especifica de tu clase o una clase que se repite una vez por semana, sin fecha exacta.',
                    },
                    {
                      element: document.querySelectorAll('#collapsetotal23 .md-select')[0],
                      intro: 'Por ejemplo Todos los lunes.',
                    },
                    {
                      element: document.querySelectorAll('#collapsetotal23 .md-select')[1],
                      intro: 'También puedes asignar una rutina a esta clase. Esta será accesible en el perfil de los clientes que se inscriban a la clase.',
                    },
                    {
                      element: elements[1],
                      title: 'Tu evento o clase puede permitir inscripciones o ser abierto sin necesidad de inscripción',
                      intro: 'Si decides habiliar inscripciones, puedes elegir que solo los clientes que tengan clases disponibles puedan inscribirse. También puedes definir una cantidad limitada de lugares o cupos.',
                    }
                  ],
                })
                .start();
          
          }, 500); // Ajusta el retraso si es necesario
        });
    }, 
    assignClient() {
       this.$router.push("/plannings/explore").then(() => {
          setTimeout(() => {
            const intro = introJs();
            intro.setOptions({
              showProgress: false,
              showBullets: false,
              nextLabel: 'Siguiente',
              prevLabel: 'Atrás',
              doneLabel: 'Listo',
              steps: [
                {
                  title: 'Hola👋',
                  element: document.querySelector('.planningimage'),
                  intro: 'Primero elije una rutina que quieres asignar haciendo click sobre la imagen y luego solo debes hacer click en el botón verde "ASIGNAR A UN ALUMNO" y elegir el/los clientes para asignarle la rutina elegida',
                }
              ],
            });


         

            intro.start();
          }, 500);
        });
    },
    addRutina() {
       this.$router.push("/plannings/explore").then(() => {
          setTimeout(() => {
            const intro = introJs();
            intro.setOptions({
              showProgress: false,
              nextLabel: 'Siguiente',
              prevLabel: 'Atrás',
              doneLabel: 'Listo',
              steps: [
                {
                  title: 'Hola👋',
                  element: document.querySelector('.logoplanning'),
                  intro: 'Haz click en AGREGAR RUTINA y click en "siguiente" para comenzar la explicación.',
                },
                {
                  element: null,
                  title: 'Visibilidad',
                  intro: 'Las rutinas serán visibles para todos tus clientes excepto si la defines como privada.',
                }
              ],
            });

         

            intro.start();
          }, 500);
        });
    },
    addTeacher() {
        this.$router.push("/myteam").then(() => {
          setTimeout(() => {
              introJs()
                .setOptions({
                   showProgress: false,
                  nextLabel: 'Siguiente',
                  prevLabel: 'Atrás',
                  doneLabel: 'Listo',
                  steps: [
                    {
                      title: 'Hola👋',
                      intro: 'Haz click en "siguiente" para comenzar la explicación.',
                    },
                    {
                      element: document.querySelector('.add-user'),
                      intro: 'Para crear un perfil para tu profes debes ir a menu > mis clientes > mí equipo y hacer click en agregar usuario. Haz click en el botton y luego en "siguiente" para seguir la explicación.',
                    },
                    {
                      element: document.querySelector('.content'),
                      intro: 'Una vez en el formulario solo debes elegir el Rol "Profesor" y completar el resto de los datos. Luego con el email y la contraseña que tu insertaste el profesor podrá conectarse a su perfil.',
                    }
                  ],
                })
                .start();
          
          }, 500); // Ajusta el retraso si es necesario
        });
    },

    addClients() {
        this.$router.push("/users").then(() => {
          setTimeout(() => {
           
              introJs()
                .setOptions({
                   showProgress: false,
                  disableInteraction: true,
                  nextLabel: 'Siguiente',
                  prevLabel: 'Atrás',
                  doneLabel: 'Listo',
                  steps: [
                    {
                      title: 'Hola👋',
                      intro: 'Hay tres formas de importar a tus clientes dentro del sistema. Comencemos haz click en el boton "siguiente"',
                    },
                    {
                      element: document.querySelector('.importexcel'),
                      intro: 'Puedes hacerlo importando un archivo Excel, si no sabes como hacerlo puedes escribir a soporte en el menu y lo haremos por usted.',
                    },
                    {
                      element: document.querySelector('.add-user'),
                      intro: 'También puedes hacerlo agregandolos unos por uno.',
                    },
                    {
                      intro: 'O simplemente dejando que cada uno de tus clientes se registre por si solo al momento de escanear el QR por la primera vez.',
                    },
                  ],
                })
                .start();
          
          }, 500); // Ajusta el retraso si es necesario
        });
    },
  }
};
</script>
