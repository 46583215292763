<template>
<div class="w-100">
  <div class="card m-2 col-7 float-left" v-if="name == 'restaurant-api'">
            <span :class="info.data.Users.length > 10 ? 'badge badge-primary':''">  Quantity User: {{info.data.Users.length}} </span>
            <div 
            v-if="info.data && info.data.Users" 
            v-for="user in info.data.Users">

              <div  v-if="user.role == 'gym'"   class="bg-secondary text-white mt-3 p-2">
              <span>Type: {{ user.role }}</span><br>
              BD: {{user.restaurant}}<br>
              Nombre:{{user.name}}<br>
              Email:{{user.email}}<br>
              Creado: {{user.createdAt}}
              <button class="btn btn-primary" @click="openClient(user.restaurant)">Abrir</button>

            </div>


            </div>



     </div>  
  
   <div class="card m-2 col-5" v-if="name == 'restaurant-api'" style="position:fixed;right:20px;top:10px;">
            <div 
              v-if="infoClient.data && infoClient.data.Users" 
             >
              <div  v-for="user in infoClient.data.Users"
                v-if="user.role == 'gym'" 
              class="bg-primary text-white mt-3 p-2">
                <span>Type: {{ user.role }}</span><br>
                BD: {{user.restaurant}}<br>
                Nombre:{{user.name}}<br>
                Email:{{user.email}}<br>
              Confirm:{{user.confirm}}<br>
                Creado: {{user.createdAt}}
              </div>
            </div>

     </div>  

     </div>  

</template>

<script>
import { getSettingsAction, getAdminAction, getDbInfoAction } from "@/pages/Auth/actions";

export default {
  data(){
    return{
        info: [],
        infoClient: []
    }
  },
  props: {
      name: String
  },
  created() {
    this.open('restaurant-api')
  },
  methods: {
    async open(db) {
      const dbInfo = await getDbInfoAction(db);
      this.info = dbInfo
      
    },
    async openClient(db) {
      const dbInfo = await getDbInfoAction(db);
      this.infoClient = dbInfo
      
    }
  }
};
</script>
